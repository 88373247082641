
























































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Button } from 'element-ui'
import { utils } from '@/utils/utils'
import { ContentInfo } from '@/modules/content/models/index'
import sweepCodeDialog from '@/modules/common/components/sweep-code-dialog.vue'
import { orderFree } from '@/modules/common/api'
import { CourseInfo } from '@/modules/course/models'
import { subscribeCourse } from '@/modules/course/api'
import { checkLogin, toLogin } from '@/utils/user'
import { time_utils } from '@/utils/time_utils'
import setTopAudio from '@/modules/common/components/set-top-audio.vue'
import setTopArticle from '@/modules/common/components/set-top-article.vue'
import gHlsVideo from '@/modules/common/components/g-hls-video.vue';
import activityBar from '@/modules/common/components/activity-bar.vue'
import { PintuanDetail } from '@/modules/common/models/index'
import discountInfo from '@/modules/common/components/discount-info.vue'
import { getContentTypeSn } from '@/config/typeConfig'
import SpeedBtn from '@/modules/common/components/speed-btn.vue'
import columnSkus from '@/modules/column/pages/components/column-skus.vue'
import { doColumnSubscribe } from '@/modules/column/api/index'

@Component({
  components: {
    SpeedBtn,
    [Button.name]: Button,
    sweepCodeDialog,
    setTopAudio,
    setTopArticle,
    gHlsVideo,
    activityBar,
    discountInfo,
    columnSkus
  }
})
export default class DetailHeader extends Vue {
  @Prop({ default: () => { return {} } }) detailInfo!: CourseInfo | ContentInfo
  @Prop({ default: () => { return {} } }) pintuanDetail!: PintuanDetail
  @Prop({ default: true }) showBuyBtn?: boolean
  mediaSpeed: number = 1
  isHeaderSetTop: boolean = false
  hasAudioPlay: boolean = false
  btnDisabled: boolean = false
  dialogOption: object = {
    showDialog: false,
    type: '',
    contentId: '',
    title: '请前往h5店铺支付，支付完成后刷新页面即可浏览'
  }
  createImgSrc(indexpic?: object) {
    return utils.createImgSrc(indexpic, { width: 1080 })
  }
  // 获取音频试听信息
  get freeAudioInfo() {
    let detailInfo: any = this.detailInfo
    let freeInfo: any = ''
    if (detailInfo.type == 'audio' && detailInfo.preview_config && detailInfo.preview_config.preview_type) {
      switch (detailInfo.preview_config.preview_type) {
        // 部分试听：播放试听文件
        case 'another_audio':
          freeInfo = {
            url: detailInfo.preview_config.preview_audio_url ? utils.getHttpsUrl(detailInfo.preview_config.preview_audio_url) : '',
            title: detailInfo.title,
            cover: this.getImgSrc,
            price: this.isPayOrder ? detailInfo.price : 0
          }
          break
        default:
          freeInfo = ''
          break
      }
    } else {
      freeInfo = ''
    }
    return freeInfo
  }
  // 获取视频试看信息
  get freeVideoInfo() {
    let detailInfo: any = this.detailInfo
    let freeInfo: any = ''
    // 视频 && 试学config && 试学type
    if (detailInfo.type == 'video' && detailInfo.preview_config && detailInfo.preview_config.preview_type) {
      switch (detailInfo.preview_config.preview_type) {
        // 部分试播：上传文件
        case 'another_video':
          freeInfo = {
            playData: {
              fileId: detailInfo.preview_config.preview_video_id || '',
              token: detailInfo.preview_config.preview_token || '',
            },
            cover: this.getImgSrc,
          }
          break
        // 部分试播：截取正片
        case 'partial':
          freeInfo = {
            playData: {
              fileId: detailInfo.file_id || '',
              token: detailInfo.preview_config.preview_token || '',
            },
            cover: this.getImgSrc,
          }
          break
        default:
          freeInfo = ''
          break
      }
    } else {
      freeInfo = ''
    }
    return freeInfo
  }
  get getContentType() {
    return this.detailInfo.type
  }
  get getImgSrc() {
    let detailInfo: any = this.detailInfo
    if (detailInfo.type === 'live' && detailInfo.live_type !== 8) {
      return utils.createImgSrc(detailInfo.live_indexpic, { width: 1080 })
    } else if (detailInfo.type === 'live' && detailInfo.live_type == 8) { // 小程序直播类型只有一张宣传图
      return utils.createImgSrc(detailInfo.indexpic, { width: 1080 })
    } else if (detailInfo.type === 'offlinecourse') {
      return utils.createImgSrc(detailInfo.cover_image, { width: 1080 })
    } else {
      return utils.createImgSrc(detailInfo.indexpic, { width: 1080 })
    }
  }
  get getInfo() {
    const info: any = this.detailInfo
    const type = this.getContentType
    const subscribeCount = utils.numberShowRules1(info.subscribe)
    const commentCount = utils.numberShowRules1(info.comment_count)
    let infoTips = ''
    switch (type) {
      case 'article':
      case 'audio':
      case 'video':
        infoTips = `${getContentTypeSn(type)} | ${commentCount}评论 | ${info.update_time}`
        break
      case 'live':
        infoTips = `${time_utils.formatDate(info.start_timestamp)} 开播`
        break
      case 'column':
        const update = utils.numberShowRules1(info.stage)
        const subscription = utils.numberShowRules1(info.subscribe)
        infoTips = `已更新${update}期 | ${subscription}订阅`
        break
      case 'course':
        const hourCount = utils.numberShowRules1(info.class_count)
        const chapterCount = utils.numberShowRules1(info.chapter_count)
        const updateTime = time_utils.formatDate(info.latest_lesson_update_time, 'yyyy-MM-dd')
        const updateTimeString = updateTime ? ` | ${updateTime}更新` : ''
        let sub = ''
        if (this.shopShowData.sub) {
          sub = ` | ${subscribeCount}人在学`
        }
        infoTips = `${chapterCount}章节 | ${hourCount}课时${updateTimeString}${sub}`
        break
      case 'offlinecourse':
        if (this.detailInfo.registration_deadline) {
          infoTips = `报名截止：${time_utils.formatDate(this.detailInfo.registration_deadline, 'yyyy-MM-dd hh:mm')}`
        }
        break
      default:
        infoTips = ''
        break
    }
    return infoTips
  }
  get shopShowData() {
    return this.$store.state.shopShowData
  }
  get getOriginOrderText() {
    const contentType = this.getContentType
    let orderText = ''
    switch (contentType) {
      case 'article':
      case 'audio':
      case 'video':
      case 'live':
        orderText = this.getSingleContentOrderText()
        break
      case 'column':
        orderText = '订阅专栏'
        break
      case 'course':
        orderText = this.getCourseContentOrderText()
        break
      case 'offlinecourse':
        orderText = this.getOfflineOrderText()
        break
      default:
        break
    }
    return orderText
  }
  get originPriceText() {
    if (this.isInPintuanActivity) {
      return '原价购买'
    }
  }
  get curPrice() {
    let price = ''
    if (this.isInPintuanActivity) {
      let skus = this.pintuanDetail.related_skus
      if (skus.length === 1) {
        price = (skus[0].discount_price / 100).toFixed(2)
      } else {
        price = (skus[0].discount_price / 100).toFixed(2) + '-' + (skus[skus.length - 1].discount_price / 100).toFixed(2)
      }
    } else if (this.isInLimitActivity) {
      price = this.detailInfo.limit_price
    } else {
      price = this.detailInfo.price
    }
    return price
  }
  get originPrice() {
    let price = ''
    let info = this.detailInfo
    if (this.isInPintuanActivity) {
      let skus = this.pintuanDetail.related_skus
      if (skus.length === 1) {
        price = (skus[0].origin_price / 100).toFixed(2)
      } else {
        price = (skus[0].origin_price / 100).toFixed(2) + '-' + (skus[skus.length - 1].origin_price / 100).toFixed(2)
      }
    } else if (this.isInLimitActivity) {
      price = info.cost_price || info.price
    } else if (info.type === 'offlinecourse') {
      let lowestPrice = (info.lowest_original_price / 100).toFixed(2)
      let highestPrice = (info.highest_original_price / 100).toFixed(2)
      if (!+lowestPrice) return 0
      if (lowestPrice === highestPrice) {
        price = lowestPrice
      } else {
        price = `${lowestPrice} -￥${highestPrice}`
      }
    } else {
      price = info.original_price
    }
    return price
  }
  get skuPrice() {
    let price = ''
    if (this.isInPintuanActivity) {
      price = (this.pintuanDetail.related_skus[0].discount_price / 100).toFixed(2)
    } else if (this.isInLimitActivity) {
      price = this.detailInfo.limit_price
    } else {
      price = this.getSkuPrice()
    }
    return price
  }
  get skuPriceText() {
    const contentType = this.getContentType
    let skuPriceText = ''
    switch (contentType) {
      case 'article':
      case 'audio':
      case 'video':
      case 'live':
        skuPriceText = ''
        break
      case 'course':
        skuPriceText = this.courseSkuPriceText()
        break
      default:
        skuPriceText = ''
        break
    }
    return skuPriceText
  }
  get multiSku() {
    const contentType = this.getContentType
    let multiSku = false
    switch (contentType) {
      case 'article':
      case 'audio':
      case 'video':
      case 'live':
        multiSku = false
        break
      case 'course':
        multiSku = this.courseIsMultiSku()
        break
      case 'offlinecourse':
        multiSku = true
        break
      default:
        multiSku = false
        break
    }
    return multiSku
  }
  get offlinecoursePrice() {
    const info = this.detailInfo
    let skuPrice = ''
    let lowestPrice = (info.lowest_price / 100).toFixed(2)
    let highestPrice = (info.highest_price / 100).toFixed(2)
    if (lowestPrice === highestPrice) {
      skuPrice = lowestPrice
    } else {
      skuPrice = `${lowestPrice} -￥${highestPrice}`
    }
    return skuPrice
  }
  // 在线招生是否有库存
  get offlineHasInventory() {
    if (this.detailInfo.type !== 'offlinecourse') return false
    let stock = 0
    Object.keys(this.detailInfo.skus).forEach(e => {
      let sku = this.detailInfo.skus[e]
      stock += sku.inventory
    })
    return !!stock
  }
  // 在线招生报名时间是否截止
  get offlineOverDeadline() {
    if (this.detailInfo.type !== 'offlinecourse') return false
    let nowTime = +new Date()
    let deadline = this.detailInfo.registration_deadline * 1000
    return deadline && deadline < nowTime
  }
  get isKeyGoods() {
    const detailInfo = this.detailInfo
    // 课程是 pay_type
    if (detailInfo.payment_type == 5 || (detailInfo as CourseInfo).pay_type == 5) {
      return !detailInfo.is_subscribe && detailInfo.lecturer !== 1
    } else {
      return false
    }
  }
  get isPayOrder() {
    const contentType = this.getContentType
    let isPayOrder = false
    switch (contentType) {
      case 'article':
      case 'audio':
      case 'video':
      case 'live':
        isPayOrder = this.contentIsPayOrder()
        break
      case 'course':
        isPayOrder = this.courseIsPayOrder()
        break
      default:
        isPayOrder = this.contentIsPayOrder()
        break
    }
    return isPayOrder
  }
  get isInPintuanActivity() {
    let data = this.pintuanDetail
    let n = +new Date()
    let s = data.start_time * 1000
    let e = data.end_time * 1000
    return n >= s && n < e && data.activation
  }
  get isInLimitActivity() {
    let data = this.detailInfo
    // let n = +new Date()
    // let s = +new Date(data.limit_start)
    // let e = +new Date(data.limit_end)
    // return n >= s && n < e && data.limit_price
    return !!data.limit_price
  }
  get activityType() {
    if (this.isInPintuanActivity) {
      return 'pintuan'
    } else if (this.detailInfo.limit_price) {
      return 'limit'
    }
  }
  get isPwdJoin() {
    // 仅用于内容类型，判断是否密码加入，密码加入不展示价格
    const contentInfo = this.detailInfo as any;
    const contentType = this.getContentType
    let flag = false
    switch (contentType) {
      case 'article':
      case 'audio':
      case 'video':
      case 'live':
        flag = this.isKeyGoods && !contentInfo.is_subscribe && !contentInfo.password_course_free
        break
      case 'course':
        flag = false
        break
      default:
        flag = false
        break
    }
    return flag
  }
  mounted() {
    window.onscroll = () => {
      if (this.$refs.detailHeaderBox) {
        let detailHeaderBox: any = this.$refs.detailHeaderBox
        this.isHeaderSetTop = detailHeaderBox.getBoundingClientRect().bottom < 50
      }
    }
  }
  beforeDestroy() {
    window.onscroll = null
  }
  toPlayAudio() {
    this.hasAudioPlay = true
    this.$nextTick(() => {
      let setTopAudio: any = this.$refs.setTopAudio
      setTopAudio.play()
    })
  }
  getSingleContentOrderText() {

    if (this.isKeyGoods) {
      return '密码获取'
    } else if (this.isInPintuanActivity) {
      return '开团'
    } else if (this.isInLimitActivity) {
      return '立即抢'
    } else if (this.isPayOrder) {
      return '购买'
    } else {
      return '订阅'
    }
  }
  getSkuPrice() {
    const contentType = this.getContentType
    let skuPrice = ''
    switch (contentType) {
      case 'article':
      case 'audio':
      case 'video':
      case 'live':
        skuPrice = this.detailInfo.price
        break
      case 'course':
        skuPrice = this.courseSkuPrice()
        break
      case 'offlinecourse':
        skuPrice = this.offlinecoursePrice
        break
      default:
        skuPrice = ''
        break
    }
    return skuPrice
  }
  courseSkuPriceText() {
    const detailInfo = this.detailInfo
    let skuPriceText = ''
    let specs = !utils.isBlank((detailInfo as CourseInfo).specs) &&
    !utils.isBlank((detailInfo as CourseInfo).specs[0]) ? (detailInfo as CourseInfo).specs : {}
    let value = specs[0] && specs[0].values && specs[0].values.length ? `${specs[0].values[0]}` : ''
    if (utils.isNumber(value)) {
      skuPriceText = `/${value}天`
    } else if (utils.isString(value)) {
      skuPriceText = utils.isNumberString(value) ? `/${value}天` : `/${value}`
    } else {
      skuPriceText = ''
    }
    return skuPriceText
  }
  courseSkuPrice() {
    const detailInfo = this.detailInfo
    let skuPrice = ''
    let skus = !utils.isBlank((detailInfo as CourseInfo).skus) &&
    !utils.isBlank((detailInfo as CourseInfo).skus[0]) ? (detailInfo as CourseInfo).skus : {}
    skuPrice = skus[0] && skus[0].unit_price ? utils.getYuanPriceUnit(skus[0].unit_price) : ''
    return skuPrice
  }
  contentIsPayOrder() {
    const detailInfo = this.detailInfo
    let isPayOrder = false
    if (utils.isBlank(detailInfo.cost_price) && detailInfo.price === '0.00') {
      isPayOrder = false
    } else {
      isPayOrder = true
    }
    return isPayOrder
  }
  courseIsPayOrder() {
    const detailInfo = this.detailInfo
    const isKeyGoods = this.isKeyGoods
    let isPayOrder = false
    if (isKeyGoods || (detailInfo as CourseInfo).pay_type == 0 || (utils.isBlank(detailInfo.cost_price) && detailInfo.price === '0.00')) {
      isPayOrder = false
    } else {
      isPayOrder = true
    }
    return isPayOrder
  }
  courseIsMultiSku() {
    const detailInfo = this.detailInfo
    let isMulti = !utils.isBlank((detailInfo as CourseInfo).skus) &&
    !utils.isBlank((detailInfo as CourseInfo).skus[0])
    return isMulti
  }
  getCourseContentOrderText() {

    if (this.isKeyGoods) {
      return '密码获取'
    } else if (this.isInPintuanActivity) {
      return '开团'
    } else if (this.isInLimitActivity) {
      return '立即抢'
    } else if (this.isPayOrder) {
      return '加入课程'
    } else {
      return '加入课程'
    }
  }
  getOfflineOrderText() {
    if (!this.offlineHasInventory) {
      this.btnDisabled = true
      return '已售罄'
    } if (this.offlineOverDeadline) {
      this.btnDisabled = true
      return '报名已截止'
    } else if (this.isInPintuanActivity) {
      return '开团'
    } else {
      return '立即报名'
    }
  }
  toBuyBtnClick() {
    const contentType = this.getContentType
    switch (contentType) {
      case 'article':
      case 'audio':
      case 'video':
      case 'live':
        this.toBuy()
        break
      case 'column':
        this.colunmTobuy()
        break
      case 'course':
        this.courseToBuy()
        break
      case 'offlinecourse':
        this.offlinecourseToBuy()
        break
      default:
        break
    }
  }
  colunmTobuy() {
    if (this.isKeyGoods || this.isPayOrder) {
      let route = {
        name: 'Bricolumn',
        params: {
          id: this.detailInfo.column_id
        }
      }
      this.$showH5qrCode(route, '请前往h5店铺支付，支付完成后<br>刷新页面即可浏览')
    } else {
      this.colunmOrderFree()
    }
  }
  courseToBuy() {
    if (this.isKeyGoods || this.isPayOrder) {
      let route = {
        name: 'Bricourse',
        params: {
          id: this.detailInfo.content_id
        }
      }
      this.$showH5qrCode(route, '请前往h5店铺支付，支付完成后<br>刷新页面即可浏览')
    } else {
      this.courseOrderFree()
    }
  }

  toBuy() {

    if (this.isKeyGoods || this.isPayOrder) {
      this.dialogOption = {
        showDialog: true,
        type: this.detailInfo.type,
        contentId: this.detailInfo.content_id,
        title: '请前往h5店铺支付，支付完成后刷新页面即可浏览'
      }
    } else {
      this.orderFree()
    }
  }

  offlinecourseToBuy() {
    if (this.btnDisabled) return
    let route = {
      name: 'offlineDetail',
      params: {
        goodsId: this.detailInfo.id
      }
    }
    this.$showH5qrCode(route, '请前往h5店铺报名支付')
  }

  orderFree() {
    // 免费订阅接口
    // orderFree().then(data => {

    // }).catch(res => {

    // })
  }
  courseOrderFree() {
    // 免费订阅接口
    if (!checkLogin()) {
      let successUrl = window.location.href
      toLogin(successUrl)
    }
    subscribeCourse({
      course_id: this.detailInfo.content_id
    }).then(resData => {
      if (resData && resData.expire_time) {
        // 会员免费 显示 有效期
        let endDate = (new Date(Date.parse(resData.expire_time.replace(/-/g, '/')))).getTime()
        let curDate = (new Date()).getTime()
        let offset = (endDate - curDate) / 1000
        let onDay = 24 * 60 * 60
        let result = Math.floor(Math.floor(offset) / onDay)
        let tips = '加入成功，有效期截止至 ' + resData.expire_time

        // 大于20年 说明是 永久 不显示有效期
        if (result / 365 > 20) {
          tips = '加入成功'
        }
        this.$message(tips)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } else {
        window.location.reload()
      }
    }).catch(() => {
      this.$message('加入失败')
    })
  }
  colunmOrderFree() {
    // 免费订阅接口
    if (!checkLogin()) {
      let successUrl = window.location.href
      toLogin(successUrl)
    }
    doColumnSubscribe({
      column_id: this.detailInfo.column_id
    }).then(resData => {
      if (resData && resData.expire_time) {
        // 会员免费 显示 有效期
        let endDate = (new Date(Date.parse(resData.expire_time.replace(/-/g, '/')))).getTime()
        let curDate = (new Date()).getTime()
        let offset = (endDate - curDate) / 1000
        let onDay = 24 * 60 * 60
        let result = Math.floor(Math.floor(offset) / onDay)
        let tips = '加入成功，有效期截止至 ' + resData.expire_time

        // 大于20年 说明是 永久 不显示有效期
        if (result / 365 > 20) {
          tips = '加入成功'
        }
        this.$message(tips)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } else {
        window.location.reload()
      }
    }).catch(() => {
      // this.$message('加入失败')
    })
  }
}
